@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Identity by HTML5 UP
/// html5up.net | @n33co
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icon */

	.icon {
		@include icon;
		position: relative;
		border-bottom: none;

		fill: currentColor;
		width: 2em;
		height: 2em;
		margin: .75em auto;
		overflow: hidden;
	}