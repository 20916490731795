@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import "~@fontsource/source-sans-pro/300.css";

/*
	Identity by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 960px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
		xxsmall: '(max-width: 360px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border'
	));

	@mixin icon-alt($content: false, $link: false) {
		@include icon($content);
		$size: _size(border-width) * 1.25;

		&:before {
			color: _palette(bg);
			text-shadow: $size 0px 0px _palette(border),
						($size * -1) 0px 0px _palette(border),
						0px $size 0px _palette(border),
						0px ($size * -1) 0px _palette(border);

		}

		@if $link {
			&:hover {
				&:before {
					text-shadow: $size 0px 0px _palette(highlight),
								($size * -1) 0px 0px _palette(highlight),
								0px $size 0px _palette(highlight),
								0px ($size * -1) 0px _palette(highlight);
				}
			}
		}
	}

// Base.

	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/icon';
	@import 'components/list';

// Layout.

	@import 'layout/main';
	@import 'layout/footer';
	@import 'layout/wrapper';
